import { computed } from "vue"
import timeHelpers from "@/helpers/time_helpers"
import helpers from "@/helpers"
import { useStore } from "@/store"
import i18n from "@/plugins/i18n"


const states = [
  {
    value: "not_started",
    color: "blue-grey",
    icon: "mdi-calendar-remove-outline",
    label: i18n.t("quiz.sessions.state.notStarted"),
    allowManualStart: true,
    allowManualStop: false,
  },
  {
    value: "scheduled",
    color: "blue",
    icon: "mdi-calendar-clock-outline",
    label: i18n.t("quiz.sessions.state.scheduled"),
    allowManualStart: true,
    allowManualStop: false,
  },
  {
    value: "open",
    color: "green",
    icon: "mdi-play-circle-outline",
    label: i18n.t("quiz.sessions.state.open"),
    allowManualStart: false,
    allowManualStop: true,
  },
  {
    value: "closed",
    color: "red",
    icon: "mdi-stop-circle-outline",
    label: i18n.t("quiz.sessions.state.closed"),
    allowManualStart: true,
    allowManualStop: false,
  },
]

export function useQuizSession(session) {

  const store = useStore()

  const state = computed(() => {
    return states.find(s => s.value === session.state)
  })

  const pendingStateChange = computed(() => {
    return !!session.manual_open_requested_at
  })

  const color = computed(() => {
    return state.value.color
  })

  const icon = computed(() => {
    return state.value.icon
  })

  const dateRange = computed(() => {
    return timeHelpers.formatDateRange(session.start_at, session.end_at)
  })

  const startsAndEndsOnSameDay = computed(() => {
    return helpers.isSameDay(session.start_at, session.end_at)
  })

  const allowManualStart = computed(() => {
    return state.value.allowManualStart
  })

  const allowManualStop = computed(() => {
    return state.value.allowManualStop
  })

  const withDefaultValue = ({ existingSessions } = { existingSessions: [] }) => {
    return {
      name: `Session ${existingSessions.length + 1}`,
      sessionable_type:  "Quizzes::GeneralSession",
      allow_self_enrollment: "none",
      sessionable_attributes: { quizzes_group_ids: [] },
      ...state.value,
    }
  }

  // Todo: session.quizzes_group_ids is deprecated, use session.sessionable.quizzes_group_ids instead when available
  const groups = computed(() => {
    return store.getters.quizzesGroups(session.quiz_id).filter(g => session.sessionable_attributes.quizzes_group_ids.includes(g.id))
  })

  return {
    color,
    icon,
    dateRange,
    state,
    pendingStateChange,
    allowManualStart,
    allowManualStop,
    startsAndEndsOnSameDay,
    withDefaultValue,
    groups,
  }
}
